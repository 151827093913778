import Helpers from './helpers';
import { uploadFileToS3 } from './aws';
const instHelpers = new Helpers();


export const new_batch_HH = async (file, brandid, selectedBrand, userInit,notes) => {

    const requestedBy = `${userInit.fname} ${userInit.lname.charAt(0)}.`;

    if (!file || !brandid || !selectedBrand || !userInit) {
        alert('Please select Brand/Segment');
        return 0;
    }
    else if(!file.name.endsWith('.xls') && !file.name.endsWith('.xlsx')){
        alert('Please upload correct template');
        return 0;
    }

    let segmentInit = selectedBrand == 'hhsports' ? 'HHS' : 'HHW';
    const fileName = `${instHelpers.generateFileName(segmentInit)}.xlsx`;
    let catalog = selectedBrand == 'hhsports' ? 'ASAPSPORT' : 'ASAPWW'

    let dataToInput = {
        segment: selectedBrand,
        orderBy: requestedBy,
        batchID: `${fileName.slice(0, -5)}`,
        catalog: `${catalog}`
    }

    let newFile = await instHelpers.addDataToExcel(file, fileName, dataToInput);
    if (newFile === 'Invalid') {
        return 0;
    }
    else {
        let totalItems = await instHelpers.getTotalItems(file);

        await uploadFileToS3('imports', newFile, fileName);
        let reqBody = {
            name: 'new',
            reqType: 'batch',
            brandID: `${brandid}`,
            totalItems: totalItems.e.r,
            value: fileName.slice(0, -5),
            requestedBy: requestedBy,
            requestedDate: `${new Date().toISOString().slice(0, 10).replace(/-/g, '')}`,
            selectedBrand: selectedBrand,
            batchNotes: notes
        }
        try {
            const response = await fetch(`/api/app/new/batch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userInit.token}`
                },
                body: JSON.stringify(reqBody)
            });
            return(await response.json());
        } catch (error) {
            return 0;
        }
    }
};

