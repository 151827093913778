import React, { createContext, useState } from 'react';

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
    // const [userData, setUserdata] = useState('');
    
    const getLocal = localStorage.getItem('user');
    const userInit = JSON.parse(getLocal);

    return (
        <GlobalContext.Provider value={{ userInit }}>
            {children}
        </GlobalContext.Provider>
    );
};

export { GlobalContext, GlobalProvider };
