import React, { useState, useEffect } from "react";
import logo from '../../assets/images/logo-clean.png';

const Modal = ({ isOpen, closeModal, contentElements }) => {
    const [isModalOpen] = useState(false);

    const handleModalContentClick = (event) => {
        event.stopPropagation();
    };


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && !event.target.closest('#modal-content')) {
                closeModal();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isModalOpen, closeModal]);


    const ModalContent = ({ element = null }) => {
        return (
            <div id="modal-content" className="bg-white flex p-6 rounded-lg m-5 shadow-md min-w-80 min-h-10 max-w-96 h-auto justify-center flex-col items-center border-t-[0.7rem] border-[#D55440]">
                <div className="flex justify-center mb-2 p-1 w-[2.5rem] h-[4rem] scale-125 transition">
                    <img className="h-full w-full" src={logo} alt='Order Swift' />
                </div>
                <div className="text-center w-[90%]">
                    <div className="font-bold text-2xl mt-2">
                        Order Form
                    </div>
                    <div className="text-sm mt-1">
                        Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit
                    </div>
                </div>
                <div className="rounded-md h-full flex flex-col justify-center text-center p-6 max-w-80 mt-1">
                    {element}
                </div>
            </div>
        );
    };


    return (
        <>
            {isOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 modal-overlay z-20" onClick={closeModal}>
                    <div className="modal-content" onClick={handleModalContentClick}>
                        <ModalContent element={contentElements} />

                    </div>
                </div>
            )}
        </>
    );
};


const DialogBox = ({ isOpen, closeModal, contentElements }) => {
    const [isModalOpen] = useState(false);

    const handleModalContentClick = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && !event.target.closest('#modal-content')) {
                closeModal();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isModalOpen, closeModal]);

    const DialogContent = ({ element = null }) => {
        return (
            <div id="modal-content" className="bg-white flex p-6 rounded-lg m-5 shadow-md min-w-auto max-h-[80vh] h-auto justify-center flex-col items-center border-t-[0.7rem] border-[#D55440]">
                <div className="rounded-md min-h-2 max-h-[80%]  flex flex-col justify-start overflow-auto text-center w-full mt-4">
                    {element}
                </div>
            </div>
        );
    };

    return (
        <>
            {isOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 modal-overlay z-20" onClick={closeModal}>
                    <div className="modal-content w-auto" onClick={handleModalContentClick}>
                        <DialogContent element={contentElements} />
                    </div>
                </div>
            )}
        </>
    );
};


export { Modal, DialogBox };
