import React, { useState } from 'react';

const UploadOrder = ({ fileHandler, templateHandler, sampleTemplate }) => {
    const [fileName, setFileName] = useState('No file chosen');
    const [file, setFile] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [notes, setNotes] = useState('');

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = () => {
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragActive(false);
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const selectedFile = files[0];
            setFileName(selectedFile.name);
            setFile(selectedFile);
        }
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files.length > 0) {
            const selectedFile = files[0];
            setFileName(selectedFile.name);
            setFile(selectedFile);
        }
    };

    const handleSubmit = () => {
        if (file) {
            fileHandler(file, selectedBrand, notes);
            setFile(null);
            setFileName('No file chosen');
            setNotes('');
        } else {
            alert('No file selected');
        }
    };

    const handleBrandChange = (e) => {
        setSelectedBrand(e.target.value);
    };

    const handleTextareaChange = (e) => {
        setNotes(e.target.value);
    };

    const handleSampleTemplate = () => {
        sampleTemplate();
    };

    const handleDlTemplate = () => {
        templateHandler();
    };

    return (
        <div>
            <div className="flex justify-center flex-col items-center">
                <button onClick={handleDlTemplate} className="scale-75 font-medium flex items-center p-2 px-3 rounded-xl text-[#21A366] border-2 border-[#148248] hover:bg-[#4f9977] hover:text-white transition-colors duration-200">
                    <span className="mr-2">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                            <rect width="16" height="9" x="28" y="15" fill="#21a366"></rect>
                            <path fill="#185c37" d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"></path>
                            <rect width="16" height="9" x="28" y="24" fill="#107c42"></rect>
                            <rect width="16" height="9" x="12" y="15" fill="#3fa071"></rect>
                            <path fill="#33c481" d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"></path>
                            <path fill="#21a366" d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"></path>
                            <path d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z" opacity=".05"></path>
                            <path d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425C25.333,34.603,23.936,36,22.213,36z" opacity=".07"></path>
                            <path d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z" opacity=".09"></path>
                            <linearGradient id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1" x1="4.725" x2="23.055" y1="14.725" y2="33.055" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stopColor="#18884f"></stop>
                                <stop offset="1" stopColor="#0b6731"></stop>
                            </linearGradient>
                            <path fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)" d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16C24,33.105,23.105,34,22,34z"></path>
                            <path fill="#fff" d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526l3.193-5.018L9.807,19z"></path>
                        </svg>
                    </span>
                    OS_orderForm_v1.0.0.xlsx
                </button>

                <p className='text-[#0000EE] scale-90 underline mt-2 hover:cursor-pointer' onClick={handleSampleTemplate}>Download sample format here</p>

                <select
                    className="mt-2 p-1 mb-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#21A366]"
                    value={selectedBrand}
                    onChange={handleBrandChange}
                >
                    <option value="">Select a brand</option>
                    <option value="hhsports">Helly Hansen - Sport</option>
                    <option value="hhwork">Helly Hansen - Work</option>
                </select>

                <div className="scale-90 mt-2 flex items-center justify-center flex-col">
                    <input
                        type="file"
                        id="actual-btn"
                        hidden
                        onChange={handleFileChange}
                    />
                    <div
                        onClick={() => document.getElementById('actual-btn').click()}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        className={`w-15 h-15 border-4 border-dashed ${dragActive ? 'border-green-500' : 'border-[#707070]'} flex items-center justify-center cursor-pointer transition-colors duration-200 hover:bg-gray-100`}
                    >
                        <span className="text-gray-500">Drag and drop files here or click to upload</span>
                    </div>
                    <span id="file-chosen" className="mt-2">{fileName}</span>
                </div>
                <div className='w-full p-3'>
                    <textarea
                        id="message"
                        rows="4"
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Batch Notes (Optional)"
                        style={{ maxHeight: '60px', overflowY: 'auto', minHeight: '40px' }}
                        value={notes}
                        onChange={handleTextareaChange}
                    ></textarea>
                </div>
            </div>
            <button
                onClick={handleSubmit}
                disabled={!file || selectedBrand === ''}
                className={`p-2 px-9 mt-3 rounded-lg text-white transition-colors duration-200 ${file && selectedBrand? 'bg-[#D55440]' : 'bg-gray-300 cursor-not-allowed'}`}
            >
                Submit
            </button>
        </div>
    );
};

export default UploadOrder;
