import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";

export default function Users() {
    const navigate = useNavigate();

    return (
        <Layout>
            <div className="w-full h-full bg-white p-4">
                <h1>Users!</h1>
            </div>
        </Layout>
    );
}
