import React from "react";

export default function PageNotFound() {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="max-w-md text-center">
                <h1 className="text-6xl font-bold text-gray-800">404</h1>
                <p className="mt-4 text-xl text-gray-600">Oops! Page not found.</p>
                <p className="mt-2 text-gray-600">The page you are looking for might have been removed or is temporarily unavailable.</p>
                <a href="/dashboard" className="inline-block mt-8 px-6 py-3 bg-[#ea4b33] text-white rounded-lg hover:bg-[#c96252]">Go back to Home</a>
            </div>
        </div>
    );
}