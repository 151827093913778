import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Pagination = ({
    totalItems,
    itemsPerPage,
    onPageChange
}) => {
    const query = useQuery();
    const navigate = useNavigate();
    let currentPage = parseInt(query.get('page')) || 1; 

    const maxPage = Math.ceil(totalItems / itemsPerPage);

    const updatePage = (newPage) => {
        query.set('page', newPage);
        navigate({ search: query.toString() });
        onPageChange(newPage);
    };

    const handleNext = () => {
        if (currentPage < maxPage) {
            updatePage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            updatePage(currentPage - 1);
        }
    };

    const startEntry = (currentPage - 1) * itemsPerPage + 1;
    const endEntry = Math.min(currentPage * itemsPerPage, totalItems);

    return (
        <div>
            <div className="flex flex-col items-center mt-7">
                <span className="text-sm text-[#D55440] dark:text-gray-400">
                    Showing{" "}
                    <span className="font-semibold text-[#D55440]">{startEntry > endEntry ? endEntry :startEntry}</span>{" "}
                    to{" "}
                    <span className="font-semibold text-[#D55440]">{endEntry}</span>{" "}
                    of{" "}
                    <span className="font-semibold text-[#D55440]">{totalItems}</span>{" "}
                    Entries
                </span>
                <div className="inline-flex mt-2 xs:mt-0">
                    <button
                        onClick={handlePrev}
                        className="flex items-center justify-center px-3 h-8 text-sm font-medium text-[#D55440] border border-[#D55440] rounded-s mr-1 dark:text-gray-400 hover:bg-[#D55440] hover:text-[#fff]"
                    >
                        Prev
                    </button>
                    <button
                        onClick={handleNext}
                        className="flex items-center justify-center px-3 h-8 text-sm font-medium text-[#D55440] border border-[#D55440] rounded-e dark:text-gray-400 bg-[#fff] hover:bg-[#D55440] hover:text-white"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
