import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sysman from "./sysman";
import Modal from "../../components/sysman/components/modal";
import LoadingBar from 'react-top-loading-bar';

const getLocal = () => {
    let localDataInit = localStorage.getItem('user');
    return JSON.parse(localDataInit);
}


const getBrands = async (setBrands) => {
    const response = await fetch(`/api/app/brands/all`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `${getLocal().token}`
        }
    });
    const data = await response.json();
    setBrands(data.brand);
}

const Brands = () => {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [brands, setBrands] = useState([]);
    const navigate = useNavigate();

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent({});
    };

    useEffect(() => {
        getBrands(setBrands);
    }, []);

    const handleCardClick = (brandID) => {
        setLoading(true);
        setProgress(25);
        if (brandID == 'T1-3213123') {
            setIsModalOpen(true);
            setModalContent('Brand is not yet automated!');
            setProgress(100);
            setLoading(false);
            return;
        }
        setProgress(100);
        setLoading(false);
        navigate(`/client/sysman/testpage/${brandID}`);

    }

    return (
        <Sysman>
            <div className="flex-1 flex flex-col w-full h-4/5">
                <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} contentElements={modalContent} />
                <LoadingBar progress={progress} color="#D55440" onLoaderFinished={() => setProgress(0)} height={3} />
                <div className="mt-4 ml-4">
                    <h1 className="font-bold">Please select a brand</h1>
                </div>
                <div className="justify-start flex items-start w-full flex-wrap">
                    {brands.map((brand) => (
                        <div
                            key={brand.brandID}
                            onClick={() => handleCardClick(brand.brandID)}
                            className="m-4 h-32 w-48 p-4 border border-gray-300 rounded cursor-pointer bg-white shadow-lg hover:bg-gray-100 transition"
                        >
                            <h2 className="text-xl font-bold">{brand.name}</h2>
                            <p>{brand.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Sysman>
    );
}

export default Brands;
