import { EyeIcon, PencilIcon, ShoppingCartIcon, TrashIcon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';

const Table = ({
    data,
    unique,
    tHeaders,
    onDelete,
    onEdit,
    onView,
    onOrder,
    searchTerm,
    handleSearchChange,
    filterStatus,
    handleFilterChange,
    category,
    handleCategoryChange,
    title,
    paginationComponent,
    disableButtons = false
}) => {
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const headers = tHeaders.map(header => header.displayName);

    const statusMapping = {
        0: 'Failed',
        1: 'New',
        2: 'In Progress',
        3: 'Good',
        4: 'Shipped',
        5: 'For Review',
        6: 'Completed'
    };

    const statusStyles = {
        0: 'bg-red-200 text-xs rounded-xl text-red-700',
        1: 'bg-blue-200 text-xs rounded-xl text-blue-900',
        2: 'bg-yellow-200 text-xs rounded-xl text-yellow-900',
        3: 'bg-green-200 text-xs rounded-xl text-green-700',
        4: 'bg-green-200 text-xs rounded-xl text-green-700',
        5: 'bg-orange-200 text-xs rounded-xl text-orange-700',
        6: 'bg-green-200 text-xs rounded-xl text-green-700'
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelected([]);
            setShowDeleteButton(false);
        } else {
            setSelected(data.map((item) => item[unique]));
            setShowDeleteButton(true);
        }
        setSelectAll(!selectAll);
    };

    const handleSelect = (id) => {
        let updatedSelected = [...selected];
        if (updatedSelected.includes(id)) {
            updatedSelected = updatedSelected.filter((item) => item !== id);
        } else {
            updatedSelected.push(id);
        }

        setSelected(updatedSelected);
        setShowDeleteButton(updatedSelected.length > 0 && typeof onDelete === 'function' || !selectAll);
    };

    const handleDelete = () => {
        if (selected.length > 0) {
            onDelete(selected);
            setSelected([]);
            setShowDeleteButton(false);
        }
    };

    const getNestedProperty = (obj, key) => {
        return key.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const filteredData = data.filter((row) => {
        return (
            (!searchTerm || row[unique].includes(searchTerm)) &&
            (!filterStatus || statusMapping[row.status] === filterStatus) &&
            (!category || row.category === category)
        );
    });

    return (
        <div className="w-full min-h-full bg-white rounded-md p-3 flex flex-col justify-between">
            <div className="w-full flex justify-between items-center h-14 mb-2">
                <div>
                    <h1 className="font-bold text-[#D55440]">{title}</h1>
                </div>
                <div className="space-x-4 scale-90">
                    {handleSearchChange && (
                        <input
                            type="text"
                            placeholder="Search by Batch ID"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="border rounded-xl px-4 py-2 border-[#e2968a] text-[#e2968a] focus:outline-none"
                        />
                    )}
                    {handleFilterChange && (
                        <select
                            value={filterStatus}
                            onChange={handleFilterChange}
                            className="border rounded-xl px-4 py-2 border-[#e2968a] text-[#e2968a] focus:outline-none"
                        >
                            <option value="">Filter by Status</option>
                            {Object.entries(statusMapping).map(([key, value]) => (
                                <option key={value} value={value}>{value}</option>
                            ))}
                        </select>
                    )}

                    {handleCategoryChange && (
                        <select
                            value={category}
                            onChange={handleCategoryChange}
                            className="border rounded-xl px-4 py-2 border-[#e2968a] text-[#e2968a] focus:outline-none"
                        >
                            <option value="">Select Category</option>
                            <option value="Category1">Category1</option>
                            <option value="Category2">Category2</option>
                            <option value="Category3">Category3</option>
                        </select>
                    )}
                    {typeof handleDelete === 'function' && showDeleteButton && onDelete && (
                        <button className={`bg-red-200 p-2 rounded-lg hover:bg-red-400 ${disableButtons ? 'cursor-not-allowed' : ''}`} onClick={handleDelete} disabled={disableButtons} style={{ verticalAlign: 'middle' }}>
                            <TrashIcon className="w-5 h-5 text-white " />
                        </button>
                    )}
                </div>
            </div>
            <div className="overflow-x-auto w-full rounded-md flex-grow">
                <table className="min-w-full h-full bg-white">
                    <thead className="bg-[#D55440] text-gray-200 sticky top-0 border-hidden">
                        <tr>
                            <th className="w-12 px-2 py-2">
                                {onDelete ? (
                                    <input type="checkbox" checked={selectAll} onChange={handleSelectAll} />
                                ) : (
                                    <span className="inline-block w-full h-full"></span>
                                )}
                            </th>
                            {headers.map((header, index) => (
                                <th key={index}>{header}</th>
                            ))}
                            {(onView || onOrder || onEdit || onDelete) && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.length === 0 ? (
                            <tr>
                                <td colSpan={headers.length + 2} className="text-center py-4 text-gray-500">
                                    No data to show.
                                </td>
                            </tr>
                        ) : (
                            filteredData.map((row, index) => (
                                <tr
                                    key={index}
                                    className={` ${index % 2 === 0 ? 'hover:bg-[#dbd2d0] bg-gray-200' : 'hover:bg-[#dbd2d0] bg-white'} ${row.status === 0 ? 'bg-red-100' : ''
                                        }`}
                                >
                                    <td key={index} className="px-4 py-2 text-center">
                                        {onDelete ? (
                                            <input
                                                type="checkbox"
                                                checked={selected.includes(row[unique])}
                                                onChange={() => handleSelect(row[unique])}
                                            />
                                        ) : (
                                            <span className="inline-block w-full h-full"></span>
                                        )}
                                    </td>
                                    {tHeaders.map((header, index) => (
                                        <td key={index} className={`px-4 py-2 text-center ${header.key === 'notes' ? 'truncate max-w-[6rem]' : header.key === 'status' ? 'max-w-[4rem]' : ''}`}>
                                            {header.key === 'validationError' ? (
                                                <span className={`px-2 py-1 rounded ${row.validationError === "Matched" ? statusStyles[3] : statusStyles[0]}`}>
                                                    {row.validationError}
                                                </span>
                                            ) : header.key === 'status' ? (
                                                <span className={`px-2 py-1 rounded ${statusStyles[row.status]}`}>
                                                    {statusMapping[row.status]}
                                                </span>
                                            ) : (
                                                getNestedProperty(row, header.key)
                                            )}
                                        </td>

                                    ))}
                                    {(onView || onOrder || onEdit || onDelete) && (
                                        <td className="px-4 py-2 text-center">
                                            {typeof onView === 'function' && (
                                                <button
                                                    className={`ml-2 text-blue-500 hover:text-blue-700 ${disableButtons ? 'cursor-not-allowed' : ''}`}
                                                    onClick={() => onView(row[unique])}
                                                    disabled={disableButtons}
                                                >
                                                    <EyeIcon className="w-5 h-5" />
                                                </button>
                                            )}
                                            {typeof onOrder === 'function' && (
                                                <button
                                                    className={`ml-2 text-blue-500 hover:text-blue-700 ${disableButtons ? 'cursor-not-allowed' : ''}`}
                                                    onClick={() => onOrder(row[unique])}
                                                    disabled={disableButtons}
                                                >
                                                    <ShoppingCartIcon className="w-5 h-5 fill-[#D55440]" />
                                                </button>
                                            )}
                                            {typeof onEdit === 'function' && (
                                                <button
                                                    className={`ml-2 text-green-500 hover:text-green-700 ${disableButtons ? 'cursor-not-allowed' : ''}`}
                                                    onClick={() => onEdit(row[unique])}
                                                    disabled={disableButtons}
                                                >
                                                    <PencilIcon className="w-5 h-5" />
                                                </button>
                                            )}
                                            {typeof onDelete === 'function' && (
                                                <button
                                                    className={`ml-2 text-red-500 hover:text-red-700 ${disableButtons ? 'cursor-not-allowed' : ''}`}
                                                    onClick={() => onDelete([row[unique]])}
                                                    disabled={disableButtons}
                                                >
                                                    <TrashIcon className="w-5 h-5" />
                                                </button>
                                            )}
                                        </td>
                                    )}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {paginationComponent}
        </div>
    );
};

export default Table;
