import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation, Link } from 'react-router-dom';
import logo from '../assets/images/swift-logo.png';
import profile from '../assets/images/huh.jpg';
import { Breadcrumbs } from "@material-tailwind/react";
import { CheckCircleIcon, UsersIcon } from "@heroicons/react/24/solid";
import { GlobalContext } from "../appserv/GlobalContext";

export default function Layout(props) {
    const [environment, setEnvironment] = useState([]);
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    const { userInit } = useContext(GlobalContext);

    const env = async () => {
        const response = await fetch(`/api/root/env`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userInit.token}`
            }
        });

        if (response.status == 401) {
            logout();
        }
        else {
            const data = await response.json();
            setEnvironment(data);
        }

    }

    const logout = () => {
        localStorage.removeItem('user');
        window.location.reload();
    }

    const getSubHeader = () => {
        if (location.pathname === "/dashboard") {
            return `Welcome ${userInit.fname}!`;
        }
    }

    const getHeaderTitle = () => {
        if (location.pathname === "/dashboard") {
            return `Dashboard`;
        }
        else if (location.pathname === "/tools") {
            return "Ordering Tools";
        }



        return "Order Management";
    };


    const generateBreadcrumbs = () => {

        return (
            <Breadcrumbs className="p-0">
                <Link to="/dashboard" className="opacity-60 hover:text-red-200">Home</Link>
                {pathnames.map((value, index) => {
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;
                    return isLast ? (
                        <span key={to} className="text-[#ea4b33] hover:text-red-200">{value}</span>
                    ) : (
                        <Link key={to} to={to} className="opacity-60 hover:text-red-200">
                            {value}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        );
    };
    const navLinkClasses = ({ isActive }) => (
        `w-full flex flex-col justify-center items-center h-auto p-2 rounded-md border-solid border-2 ${isActive ? 'border-[#f77b69]' : 'border-white hover:border-[#f77b69]'}`
    );

    useEffect(() => {
        env();
    }, []);

    return (

        <div className="flex flex-col min-h-screen">
            <div className={`${!environment.set ? `hidden` : ``} text-center p-1 bg-[#3484b3] text-white text-sm`}>
                {environment.set ? `${environment.env} ${environment.version} ${environment.stage}` : ''}
            </div>

            <div className="flex">
                <aside className="z-10 bg-white border min-h-screen p-2 text-center flex-none overflow-y-auto shadow-lg w-20 md:w-24 lg:w-28 flex justify-between flex-col items-center">
                    <div className="w-full h-auto flex flex-col items-center space-y-6 mt-6">
                        <NavLink to="/dashboard" className="h-16 w-5/6">
                            <img src={logo} alt='order Swift' />
                        </NavLink>
                        <NavLink to="/dashboard" className={navLinkClasses}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 45.691 45.691">
                                <g id="metrics" transform="translate(1 1)">
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="8" cy="8" r="8" transform="translate(0.5 27.191)" fill="none" stroke="#ea4b33" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Path_13" data-name="Path 13" d="M2.5,12.143V.5A34.929,34.929,0,0,1,37.429,35.429H25.786" transform="translate(5.762)" fill="none" stroke="#ea4b33" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <line id="Line_6" data-name="Line 6" y1="10.036" x2="10.036" transform="translate(14.75 18.905)" fill="none" stroke="#ea4b33" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>
                            <h1 className="mt-2 text-xs">Dashboard</h1>
                        </NavLink>
                        <NavLink to="/order-management" className={navLinkClasses}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 50.214 45">
                                <path id="ic_view_list_24px" d="M4,33.929H15.815V21.071H4ZM4,50H15.815V37.143H4ZM4,17.857H15.815V5H4ZM18.769,33.929H54.214V21.071H18.769Zm0,16.071H54.214V37.143H18.769Zm0-45V17.857H54.214V5Z" transform="translate(-4 -5)" fill="#ea4b33" />
                            </svg>
                            <h1 className="mt-2 text-xs">Orders</h1>
                        </NavLink>
                        <NavLink to="/tools" className={navLinkClasses}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 56.5 62.778">
                                <path id="ic_assignment_24px" d="M53.222,7.278H40.1a9.379,9.379,0,0,0-17.7,0H9.278A6.3,6.3,0,0,0,3,13.556V57.5a6.3,6.3,0,0,0,6.278,6.278H53.222A6.3,6.3,0,0,0,59.5,57.5V13.556A6.3,6.3,0,0,0,53.222,7.278Zm-21.972,0a3.139,3.139,0,1,1-3.139,3.139A3.148,3.148,0,0,1,31.25,7.278Zm6.278,43.944H15.556V44.944H37.528Zm9.417-12.556H15.556V32.389H46.944Zm0-12.556H15.556V19.833H46.944Z" transform="translate(-3 -1)" fill="#ea4b33" />
                            </svg>
                            <h1 className="mt-2 text-xs">Tools</h1>
                        </NavLink>
                        <NavLink to="/users" className={navLinkClasses}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="38.315" viewBox="0 0 60.209 38.315">
                                <path id="ic_group_24px" d="M42.051,21.421a8.21,8.21,0,1,0-8.21-8.21A8.176,8.176,0,0,0,42.051,21.421Zm-21.894,0a8.21,8.21,0,1,0-8.21-8.21A8.176,8.176,0,0,0,20.157,21.421Zm0,5.474C13.781,26.894,1,30.1,1,36.473v6.842H39.315V36.473C39.315,30.1,26.534,26.894,20.157,26.894Zm21.894,0c-.794,0-1.7.055-2.655.137,3.175,2.3,5.391,5.391,5.391,9.442v6.842H61.209V36.473C61.209,30.1,48.428,26.894,42.051,26.894Z" transform="translate(-1 -5)" fill="#ea4b33" />
                            </svg>

                            <h1 className="mt-2 text-xs">Users</h1>
                        </NavLink>
                        <NavLink to="/settings" className={navLinkClasses}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 49.5 49.5">
                                <path id="ic_tune_24px" d="M3,41.5V47H19.5V41.5Zm0-33V14H30.5V8.5Zm27.5,44V47h22V41.5h-22V36H25V52.5ZM14,19.5V25H3v5.5H14V36h5.5V19.5Zm38.5,11V25H25v5.5ZM36,19.5h5.5V14h11V8.5h-11V3H36Z" transform="translate(-3 -3)" fill="#ea4b33" />
                            </svg>
                            <h1 className="mt-2 text-xs">Settings</h1>
                        </NavLink>

                    </div>
                    <NavLink onClick={logout} className="w-full flex flex-col justify-center items-center h-auto p-2 rounded-md">
                        <div className="w-full flex flex-col justify-center items-center h-auto p-2 rounded-md hover:bg-gray-100">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40.469" height="40.469" viewBox="0 0 40.469 40.469">
                                <path id="Path_15" data-name="Path 15" d="M8.5,3,18.719,13.219,8.5,23.437" transform="translate(20.25 7.016)" fill="none" stroke="#ea4b33" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                <line id="Line_7" data-name="Line 7" x1="27.25" transform="translate(11.719 20.234)" fill="none" stroke="#ea4b33" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                <path id="Path_16" data-name="Path 16" d="M17.531,37.969H3.906A3.406,3.406,0,0,1,.5,34.562V3.906A3.406,3.406,0,0,1,3.906.5H17.531" transform="translate(1 1)" fill="none" stroke="#ea4b33" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            </svg>

                            <h1 className="mt-2 text-sm">Logout</h1>
                        </div>
                    </NavLink>
                </aside>


                <main className="flex-1 p-4 bg-[#f3f0f0] flex w-full overflow-auto flex-col">
                    <div className="w-full h-16 flex lg:flex-row xs:flex-col justify-between">
                        <div className="flex items-start flex-col h-full justify-center">
                            <h1 className="font-bold sm:text-xl lg:text-2xl">{getHeaderTitle()}</h1>
                            <h1 className="font-normal sm:text-sm lg:text-sm">{getSubHeader()}</h1>
                        </div>
                        <div className="h-full flex items-center">
                            <div className="flex flex-row h-auto items-center scale-75">
                                <div className="mr-2 hover:scale-95 scale-90">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="57.148" height="51.181" viewBox="0 0 57.148 51.181">
                                        <g id="notifcation" transform="translate(-1504.852 -101)">
                                            <g id="notification_button" transform="translate(1504.852 104.254)">
                                                <g id="left_side_notification_button" transform="matrix(0.914, 0.407, -0.407, 0.914, 14.748, 0)">
                                                    <g id="Group_592" data-name="Group 592" transform="translate(0 0)">
                                                        <path id="Path_380" data-name="Path 380" d="M35.795,26.479c-3.672-3.485-4.416-8.269-5.135-12.9C29.671,7.216,28.549,0,18.2,0S6.721,7.216,5.732,13.583C5.013,18.21,4.27,22.994.6,26.479a1.916,1.916,0,0,0,1.32,3.306h9.019a7.307,7.307,0,0,0,14.519,0h9.019a1.916,1.916,0,0,0,1.32-3.306ZM18.2,32.906a3.96,3.96,0,0,1-3.865-3.121h7.73A3.96,3.96,0,0,1,18.2,32.906ZM5.939,25.952C8.271,22.219,8.926,18,9.521,14.171,10.606,7.189,11.428,3.833,18.2,3.833s7.59,3.356,8.675,10.339c.595,3.829,1.251,8.048,3.582,11.781Z" fill="#63799b" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="number_notify" transform="translate(1528 101)">
                                                <rect id="Rectangle_540" data-name="Rectangle 540" width="34" height="23" rx="11.5" fill="#d55440" />
                                                <text id="_13" data-name="13" transform="translate(7 1)" fill="#f5f2f2" font-size="16" font-family="SFProText-Bold, SF Pro Text" font-weight="700"><tspan x="4" y="15" className="text-xs">12</tspan></text>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="w-[50px] h-[50px] mr-5 relative">
                                    <img className="rounded-full shadow-lg border-2 border-[#d55440] p-0.5" src={profile} />
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row items-center">
                                        <h1 className="font-semibold">{userInit.fname} {userInit.lname.charAt(0)}. {`(${userInit.userID})`}</h1>
                                        <CheckCircleIcon className="h-5 w-5 ml-2 text-blue-200" />
                                    </div>
                                    <span className="font-medium text-sm">{userInit.level === 4 ? 'Administrator' : 'Standard Access'}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr className="border-t border-gray-300 my-4 w-full" />
                    <div className="mb-3">
                        {generateBreadcrumbs()}
                    </div>
                    <div className="w-full mb-4 md:mb-0 h-full">
                        {props.children}
                    </div>
                    <div>
                        <footer className="text-gray-500 bottom-0 left-0 w-full text-center mt-1">
                            © Ordering Automation by CPI Devs {new Date().getFullYear()}. All rights reserved. V{environment.version}
                        </footer>
                    </div>
                </main>
            </div>
        </div>
    );
}
