import AWS from 'aws-sdk';
import CryptoHelper from './byte';

const getLocal = () => {
    let localDataInit = localStorage.getItem('user');
    return (JSON.parse(localDataInit));
}



export async function uploadFileToS3(s3Folder, file, fileName){    

    const getAwsCreds = async () => {
        try {
            const response = await fetch('/api/root/aws', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getLocal().token}`
                }
            });
            const rawData = await response.json();
            const iv = rawData.awsKeys.data.slice(-32);
            const key = rawData.awsKeys.data.slice(0,32).toString();
            const encrypted = rawData.awsKeys.data.slice(32, -32);
            const cryptoHelper = new CryptoHelper(key);
            const decryptedData = cryptoHelper.decryptData(encrypted, iv);
            return JSON.parse(decryptedData);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    let s3KeysCred = await getAwsCreds();

    const S3Keys = {
        accessKeyId: s3KeysCred.S3_ACCESS_KEY_ID, 
        secretAccessKey: s3KeysCred.S3_SECRET_ACCESS_KEY,
        region: s3KeysCred.S3_REGION, 
    }
    AWS.config.update(S3Keys);
    const s3 = new AWS.S3()

    const params = {
        Bucket: s3KeysCred.S3_BUCKET,
        Key: s3Folder + '/' + fileName,
        Body: file,
    }

    return new Promise((resolve, reject) => {
        s3.upload(params, function(err, data) {
            if (err) {
                reject(err)
            } else {
                resolve(data.Location)
            }
        })
    })

}


export async function getFile(fileKey) {

    const getAwsCreds = async () => {
        try {
            const response = await fetch('/api/root/aws', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getLocal().token}`
                }
            });
            const rawData = await response.json();
            const iv = rawData.awsKeys.data.slice(-32);
            const encryptionKey = rawData.awsKeys.data.slice(0, 32).toString();
            const encrypted = rawData.awsKeys.data.slice(32, -32);
            const cryptoHelper = new CryptoHelper(encryptionKey);
            const decryptedData = cryptoHelper.decryptData(encrypted, iv);
            return JSON.parse(decryptedData);
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const s3KeysCred = await getAwsCreds();

    if (!s3KeysCred) {
        throw new Error('Failed to retrieve AWS credentials');
    }

    const S3Keys = {
        accessKeyId: s3KeysCred.S3_ACCESS_KEY_ID,
        secretAccessKey: s3KeysCred.S3_SECRET_ACCESS_KEY,
        region: s3KeysCred.S3_REGION,
    };
    AWS.config.update(S3Keys);
    const s3 = new AWS.S3();

    const params = {
        Bucket: s3KeysCred.S3_BUCKET,
        Key: fileKey
    };

    try {
        const data = await s3.getObject(params).promise();
        return data;
    } catch (err) {
        throw new Error(`Failed to get file: ${err.message}`);
    }
}