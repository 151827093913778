import React, { useState, useEffect, useContext } from 'react';
import Layout from '../../components/Layout';
import { useNavigate } from "react-router-dom";
import hellyHansenLogo from '../../assets/images/brands/hellyhansen.png';
import thorogood from '../../assets/images/brands/thorogood1.png';
import defaultLogo from '../../assets/images/brands/default.jpg';
import { GlobalContext } from '../../appserv/GlobalContext';

export default function Brand() {
    const [brands, setBrands] = useState([]);
    const [totalBrands, setTotalBrands] = useState(0);
    const navigate = useNavigate();
    const { userInit } = useContext(GlobalContext);

    const handleBrandClick = (brandID) => {
        if(brandID !== "HH-442524"){
            alert(`The Brand is not yet Automated`);
        }else{
            navigate(`/order-management/${brandID}`);
        }
        
    };

    const getBrands = async () => {
        try {
            const response = await fetch(`/api/app/brands/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userInit.token}`
                }
            });
            const data = await response.json();
            setBrands(data.brand);
            const brandCounts = data.brand.length;
            setTotalBrands(brandCounts);
        } catch (error) {
            console.error("Error fetching brands:", error);
        }
    };

    useEffect(() => {
        getBrands();
    }, []);

    const getBrandLogo = (brandName) => {
        switch (brandName) {
            case 'Helly Hansen':
                return hellyHansenLogo;
            case 'Thorogood':
                return thorogood;
            default:
                return defaultLogo; 
        }
    };

    return (
        <Layout>
            <div className="w-full h-full bg-white p-4">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-sm font-bold text-red-500">Please select Brand</h1>
                </div>
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-1 lg:grid-cols-2">
                    {brands.map((brand) => (
                        <div
                            key={brand._id}
                            onClick={() => handleBrandClick(brand.brandID)}
                            className="flex flex-col items-center bg-white border border-gray-200 p-4 rounded-lg shadow-lg hover:shadow-2xl hover:scale-[1.02] justify-between"
                        >
                            <div className="relative flex items-center justify-center w-auto h-[225px]">
                                <img src={getBrandLogo(brand.name)} alt={brand.name} className="h-full w-full mb-2" />
                            </div>
                            <h2 className="text-red-500 font-semibold">{brand.name}</h2>
                        </div>
                    ))}
                </div>
                <p className="mt-4 text-gray-600">{totalBrands} Active brand(s) for Ordering</p>
                <button className="p-2 bg-red-500 rounded-full text-white flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                    </svg>
                </button>
            </div>
        </Layout>
    );
}
