import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Background from "../../assets/images/bg-login.jpg";
import Modal from "../../components/sysman/components/modal";
import LoadingBar from 'react-top-loading-bar';
import { GlobalContext } from "../../appserv/GlobalContext";

const Confirmation = () => {
    const { id } = useParams();
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [internalID, setInternalID] = useState("");
    const [uName, setUname] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const navigate = useNavigate();

    const { userInit } = useContext(GlobalContext);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent({});
    };

    const checkUser = () => {
        try {
            if (userInit.token) {
                return (navigate('/dashboard'));
            }

        } catch (error) {
            return false;
        }
    }

    const validateId = async () => {
        try {
            const response = await fetch(`/pub/api/app/user/validate/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data.message) {
                navigate('/login');
            }
            else{
                setUserEmail(data.email);
                setInternalID(data.internalID);
                setUname(data.fname);
            }
        } catch (error) {
            console.error('Error validating ID:', error);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            let reqBody = {
                oid: id,
                intID: internalID,
                password: password,
                updatedBy: uName
            }
            if (reqBody.password == '') {
                setIsModalOpen(true);
                return (setModalContent(`Please complete the fields`));

            }
            if (password!==confirmPassword) {
                setIsModalOpen(true);
                setPassword("");
                setconfirmPassword("");
                return (setModalContent(`Password Does Not Match`));
            }
            setLoading(true);
            setProgress(25);

            const response = await fetch(`/pub/api/app/user/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
            });

            const data = await response.json();
            if (data.message) {
                setProgress(100);
                setLoading(false);
                setIsModalOpen(true);
                setModalContent(`Something went Wrong!`);
            }
            else{
                setProgress(100);
                setLoading(false);
                setIsModalOpen(true);
                setModalContent(`Succesful, You can now Login`);
                setTimeout(navigate('/login'),4000);
            }

        } catch (error) {
            console.error('Login failed:', error.message);
            alert(`Login failed: ${error.message}`);
        }
    };

    useEffect(() => {
        checkUser();
        validateId();
    }, []);

    return (
        <div className="h-screen flex items-center justify-center relative">
            <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} contentElements={modalContent} />
            <LoadingBar progress={progress} color="#D55440" onLoaderFinished={() => setProgress(0)} height={3} />
            <div
                className="absolute inset-0 bg-cover bg-center filter blur-sm"
                style={{ backgroundImage: `url(${Background})` }}
            ></div>

            <div className="max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl h-auto flex justify-center rounded-[20px] overflow-hidden shadow-lg bg-white bg-opacity-90 relative z-10">

                <div className="w-[80%] flex flex-col items-center justify-center bg-[#fff] p-8">

                    <div className="w-full h-16 text-center flex flex-col">
                        <h1 className="mt-6 text-2xl">Welcome to <span className="text-[#EA4B33] font-bold">Order Swift</span></h1>
                        <p className="text-sm text-gray-400">SM Ordering Automation System</p>
                    </div>
                    <div className="w-full h-80 flex flex-col justify-center">
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                className="hover:cursor-not-allowed p-3 mb-4 w-full border border-gray-300 rounded-lg text-gray-600 focus:outline-red-200"
                                placeholder="Email"
                                readonly
                                value={userEmail}
                                disabled={true}
                            />
                            <input
                                type="password"
                                className="p-3 mb-4 w-full border border-gray-300 rounded-lg text-gray-600 focus:outline-red-200"
                                placeholder="New Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <input
                                type="password"
                                className="p-3 mb-4 w-full border border-gray-300 rounded-lg text-gray-600 focus:outline-red-200"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setconfirmPassword(e.target.value)}
                            />

                            <div className="w-full flex flex-col justify-between items-start mt-3">
                                <button
                                    type="submit"
                                    className="py-2 px-4 w-full bg-[#EA4B33] text-white rounded-lg hover:bg-[#ea4b33d3]"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="mt-2 flex flex-row w-full justify-center items-center space-x-3 text-gray-600 text-[10px]">
                        <a href="#privacy">Privacy</a>
                        <p>|</p>
                        <a href="#terms-and-condition">Terms & Conditions</a>
                        <p>|</p>
                        <a href="#feedback">Feedback</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Confirmation;
