import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Login from './views/Login/Login';
import Dashboard from './views/Dashboard/Dashboard';
import Orders from './views/Orders/Orders';
import Brand from './views/Brand/Brand';
import Batch from './views/Batch/Batch';
import Masterlist from './views/Tools/Tools';
import Settings from './views/Settings/Settings';
import PageNotFound from './views/PageNotFound/PageNotFound';
import Sysman from './components/sysman/sysman';
import TestPage from './components/sysman/v_testpage';
import Brands from './components/sysman/v_brands';
import Confirmation from './views/Account/Confirmation';
import Users from './views/Users/Users';
import '@fontsource/inter';

const isAuthenticated = () => {
	const token = localStorage.getItem('user');
	try {
		if(token){
			return true;
		}
		
	} catch (error) {
		return false;
	}
}

const PrivateRoute = () => {
	return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />;
}
function App() {
	return (
		<Routes>
			<Route exact path="/" element={<Login />} />
			<Route exact path="/login" element={<Login />} />
			<Route exact path="/account/confirmation/:id" element={<Confirmation />} />
			<Route path="/" element={<PrivateRoute />}>
				<Route exact path="/dashboard" element={<Dashboard />} />
				<Route exact path="/order-management" element={<Brand />} />
				<Route exact path="/order-management/:brand" element={<Batch />} />
				<Route exact path="/order-management/:brand/:batch" element={<Orders />} />
				<Route exact path="/tools" element={<Masterlist />} />
				<Route exact path="/settings" element={<Settings />} />
				<Route exact path="/users" element={<Users />} />
				{/* <Route exact path="/client/sysman" element={<Sysman />} />
				<Route exact path="/client/sysman/testpage/:brandid" element={<TestPage />} />
				<Route exact path="/client/sysman/testpage" element={<Brands />} /> */}
				<Route path="*" element={<PageNotFound />} />
			</Route>
		</Routes>
	);
}


export default App;