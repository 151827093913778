import CryptoJS from 'crypto-js';

class CryptoHelper {
    constructor(key) {
        this.key = key;
    }

    encryptData(data, iv) {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, { iv }).toString();
        return encryptedData;
    }

    decryptData(encryptedData, iv) {
        const parsedIV = CryptoJS.enc.Hex.parse(iv);
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.key, { iv: parsedIV });
        const jsonData = decryptedData.toString(CryptoJS.enc.Utf8);

        return jsonData;
    }

    
}

export default CryptoHelper;
