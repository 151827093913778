import React, { useState, useEffect,useContext  } from "react";
import { useNavigate } from "react-router-dom";
import Background from "../../assets/images/bg-login.jpg";
import Poster from "../../assets/images/login-poster.jpg";
import Modal from "../../components/sysman/components/modal";
import LoadingBar from 'react-top-loading-bar';
import { GlobalContext } from "../../appserv/GlobalContext";

const Login = () => {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    const { userInit } = useContext(GlobalContext);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent({});
    };

    const checkUser = () => {
        try {
            if (userInit.token) {
                return(navigate('/dashboard'));
            }

        } catch (error) {
            return false;
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        

        try {
            let reqBody = {
                userID: userId,
                password: password
            }
            if (reqBody.userID == '' || reqBody.password == '') {
                setIsModalOpen(true);
                return (setModalContent(`Please complete the fields`));

            }
            setLoading(true);
            setProgress(25);

            const response = await fetch(`/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
            });

            const data = await response.json();

            if (data.token) {
                localStorage.setItem('user', JSON.stringify(data));
                setProgress(100);
                setLoading(false);
                window.location.href = '/dashboard';
            } else {
                setIsModalOpen(true);
                setModalContent(`Incorrect ID or Password`)
                setProgress(100);
                setLoading(false);
            }

        } catch (error) {
            console.error('Login failed:', error.message);
            alert(`Login failed: ${error.message}`);
        }
    };

    useEffect(() => {
        checkUser();
    }, []);

    return (

        <div className="h-screen flex items-center justify-center relative">
            <Modal isOpen={isModalOpen} closeModal={closeModal} content={modalContent} contentElements={modalContent} />
            <LoadingBar progress={progress} color="#D55440" onLoaderFinished={() => setProgress(0)} height={3} />
            <div
                className="absolute inset-0 bg-cover bg-center filter blur-sm"
                style={{ backgroundImage: `url(${Background})` }}
            ></div>

            <div className="w-full max-w-lg sm:max-w-xl md:max-w-2xl lg:max-w-3xl h-auto flex rounded-[20px] overflow-hidden shadow-lg bg-white bg-opacity-90 relative z-10">

                <div className="w-1/2 bg-red-200 flex items-center justify-center">
                    <img src={Poster} className="max-w-full h-auto" alt="Order Swift" />
                </div>


                <div className="w-1/2 flex flex-col items-center justify-start bg-[#fff] p-8">

                    <div className="w-full h-16 text-center flex flex-col">
                        <h1 className="mt-6 text-2xl">Welcome to <span className="text-[#EA4B33] font-bold">Order Swift</span></h1>
                        <p className="text-sm text-gray-400">SM Ordering Automation System</p>
                    </div>
                    <div className="w-full h-80 flex flex-col justify-center">
                        <form onSubmit={handleLogin}>
                            <input
                                type="text"
                                className="p-3 mb-4 w-full border border-gray-300 rounded-lg text-gray-600 focus:outline-red-200"
                                placeholder="User ID"
                                value={userId}
                                onChange={(e) => setUserId(e.target.value)}
                            />
                            <input
                                type="password"
                                className="p-3 mb-4 w-full border border-gray-300 rounded-lg text-gray-600 focus:outline-red-200"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="w-full flex flex-col justify-between items-start">
                                <div className="mb-7">
                                    <input
                                        type="checkbox"
                                        className="mr-2 translate-y-0.5"
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                    />
                                    <span className="text-sm text-gray-600">Remember Me?</span>
                                </div>
                                <button
                                    type="submit"
                                    className="py-2 px-4 w-full bg-[#EA4B33] text-white rounded-lg hover:bg-[#ea4b33d3]"
                                >
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="mt-2 flex flex-row w-full justify-center items-center space-x-3 text-gray-600 text-[10px]">
                        <a href="#privacy">Privacy</a>
                        <p>|</p>
                        <a href="#terms-and-condition">Terms & Conditions</a>
                        <p>|</p>
                        <a href="#feedback">Feedback</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
