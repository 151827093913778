import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { MagnifyingGlassIcon, NewspaperIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

export default function Masterlist() {
    const navigate = useNavigate();
    const test = (id=false) => {
        alert(`working ${id}`)
        navigate('/tools/toolpath');
    }

    return (
        <Layout>
            <div className="w-full h-full bg-white p-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    <div className="bg-blue-200 p-4 rounded-lg shadow-lg hover:scale-105" onClick={()=>test(1)}> 
                        <NewspaperIcon className="w-[2rem] h-[2rem]" />
                        <h2 className="text-xl font-bold">Order ID to Template</h2>
                        <p>Convert Order ID to order template</p>
                    </div>
                    <div className="bg-green-200 p-4 rounded-lg shadow-lg hover:scale-105" onClick={()=>test(2)}>
                        <MagnifyingGlassIcon className="w-[2rem] h-[2rem]" />
                        <h2 className="text-xl font-bold">Order Details Look Up</h2>
                        <p>Search Orders</p>
                    </div>
                    <div className="bg-red-200 p-4 rounded-lg shadow-lg hover:scale-105" onClick={()=>test(3)}>
                        <QuestionMarkCircleIcon className="w-[2rem] h-[2rem]" />
                        <h2 className="text-xl font-bold">Tool 3</h2>
                        <p>Donec euismod dapibus erat in feugiat</p>
                    </div>
                    <div className="bg-yellow-200 p-4 rounded-lg shadow-lg hover:scale-105" onClick={()=>test(4)}>
                        <QuestionMarkCircleIcon className="w-[2rem] h-[2rem]" />
                        <h2 className="text-xl font-bold">Tool 4</h2>
                        <p>Fusce blandit dapibus leo, vitae sollicitudin</p>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
