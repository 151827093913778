import React from "react";
import Layout from "../../components/Layout";
import TickPlacementBars from "../../components/app_components/cGraphs";
import { ArrowTrendingUpIcon } from "@heroicons/react/24/solid";
import profile from '../../assets/images/huh.jpg';

const Dashboard = () => {
    return (
        <Layout>
            <div className="w-full min-h-full bg-white rounded-md p-3 grid gap-4 font-bold text-[#3f3c3c]">
                <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
                    <div className="bg-[#fffcfc] shadow-lg flex items-center justify-center h-28 flex-col">
                        <div>
                            <h1>PENDING ORDERS</h1>
                        </div>
                        <div className=" text-4xl font-black text-[#417f92] flex flex-row items-center">
                            <h1>5</h1>
                        </div>
                    </div>
                    <div className="bg-[#fffcfc] shadow-lg flex items-center justify-center h-28 flex-col">
                        <div>
                            <h1>TOTAL ORDERS THIS WEEK</h1>
                        </div>
                        <div className=" text-4xl font-black text-[#417f92] flex flex-row items-center">
                            <h1>12</h1>
                        </div>
                    </div>
                    <div className="bg-[#fffcfc] shadow-lg flex items-center justify-center h-28 flex-col">
                        <div>
                            <h1>TOP BRAND TOTAL SALES</h1>
                        </div>
                        <div className=" text-4xl font-black text-[#489e56] flex flex-row items-center">
                            <h1>$5403.00 </h1>
                            <span><ArrowTrendingUpIcon className="h-5 w-5 ml-2" /></span>

                        </div>
                    </div>
                    <div className="bg-[#fffcfc] shadow-lg flex items-center justify-center h-28 flex-col">
                        <div>
                            <h1>TOP BRAND</h1>
                        </div>
                        <div className=" text-4xl font-black text-[#D55440] flex flex-row items-center">
                            <h1>HELLY HANSEN</h1>
                        </div>
                    </div>
                </div>

                <div className="w-full grid grid-cols-1 gap-4 shadow-lg ">
                    <div className="bg-[#fff] flex items-center justify-center h-auto">
                        <div className="w-full">
                            <TickPlacementBars />
                        </div>
                    </div>
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="bg-[#fff] flex items-start justify-start flex-col h-auto w-full  shadow-lg p-5 ">
                        <div className=" text-xl font-bold mb-2">
                            <h1>SM TEAM</h1>
                        </div>

                        <div className="space-y-2 w-full flex-col">

                            <div className="flex w-full flex-col hover:bg-[#f0eded] hover:scale-[1.02] ">
                                <div className="w-full flex flex-row justify-between h-12 items-center shadow-lg p-2 font-semibold">
                                    <div className="flex flex-row space-x-5">
                                        <div className="w-[35px] h-[35px] ">
                                            <img className="rounded-full" src={profile} />
                                        </div>
                                        <div className="text-[#2a4955]">
                                            <h1>Dwayne Johnson</h1>
                                        </div>

                                    </div>

                                    <div className="bg-[#b7e6e7] text-sm p-1 rounded-2xl px-2 text-[#2464FC]">
                                        Manager
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col hover:bg-[#f0eded] hover:scale-[1.02]">
                                <div className="w-full flex flex-row justify-between h-12 items-center shadow-lg p-2 font-semibold">
                                    <div className="flex flex-row space-x-5">
                                        <div className="w-[35px] h-[35px] ">
                                            <img className="rounded-full" src={profile} />
                                        </div>
                                        <div className="text-[#2a4955]">
                                            <h1>Sylvia Bates</h1>
                                        </div>
                                    </div>

                                    <div className="bg-[#90ccc2] text-sm p-1 rounded-2xl px-2 text-[#1a3f3c]">
                                        Member
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col hover:bg-[#f0eded] hover:scale-[1.02]">
                                <div className="w-full flex flex-row justify-between h-12 items-center shadow-lg p-2 font-semibold">
                                    <div className="flex flex-row space-x-5">
                                        <div className="w-[35px] h-[35px] ">
                                            <img className="rounded-full" src={profile} />
                                        </div>
                                        <div className="text-[#2a4955]">
                                            <h1>Curtis Trejo</h1>
                                        </div>
                                    </div>

                                    <div className="bg-[#90ccc2] text-sm p-1 rounded-2xl px-2 text-[#1a3f3c]">
                                        Member
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-full flex-col hover:bg-[#f0eded] hover:scale-[1.02]">
                                <div className="w-full flex flex-row justify-between h-12 items-center shadow-lg p-2 font-semibold">
                                    <div className="flex flex-row space-x-5">
                                        <div className="w-[35px] h-[35px] ">
                                            <img className="rounded-full" src={profile} />
                                        </div>
                                        <div className="text-[#2a4955]">
                                            <h1>Lylah Bush</h1>
                                        </div>
                                    </div>

                                    <div className="bg-[#90ccc2] text-sm p-1 rounded-2xl px-2 text-[#1a3f3c]">
                                        Member
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>


                    <div className="bg-[#fff] flex items-start justify-start flex-col h-auto w-full shadow-lg p-5">
                        <div className="text-xl font-bold mb-2">
                            <h1>COMPARISON</h1>
                        </div>
                        <div className="space-y-2 w-full flex-col">
                            <div className="flex w-full flex-col hover:bg-[#f0eded] hover:scale-[1.02]">
                                <div className="w-full flex flex-col justify-between h-auto items-start shadow-lg p-2 font-semibold">
                                    <div className="flex flex-row">
                                        <div className="text-[#2a4955]">
                                            <h1>Helly Hansen - Sportswear</h1>
                                        </div>
                                    </div>

                                    <div className=" w-full flex flex-row justify-start items-center p-1 rounded">
                                        <div className="bg-green-500 h-4 rounded" style={{ width: '85%' }}>
                                        </div>
                                        <div className="ml-2 text-[#2a4955] font-bold">
                                            85%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-2 w-full flex-col">
                            <div className="flex w-full flex-col hover:bg-[#f0eded] hover:scale-[1.02]">
                                <div className="w-full flex flex-col justify-between h-auto items-start shadow-lg p-2 font-semibold">
                                    <div className="flex flex-row">
                                        <div className="text-[#2a4955]">
                                            <h1>Helly Hansen - Workwear</h1>
                                        </div>
                                    </div>

                                    <div className=" w-full flex flex-row justify-start items-center p-1 rounded">
                                        <div className="bg-green-500 h-4 rounded" style={{ width: '56%' }}>
                                        </div>
                                        <div className="ml-2 text-[#2a4955] font-bold">
                                            56%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </Layout>
    );
};

export default Dashboard;
