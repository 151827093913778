import React, { useState, useEffect, useContext } from 'react';
import Pagination from '../../components/app_components/cPagination';
import Table from '../../components/app_components/cTable';
import Layout from '../../components/Layout';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../components/app_components/cModal';
import UploadOrder from '../../components/app_components/cUploadOrder';
import LoadingBar from 'react-top-loading-bar';
import { GlobalContext } from '../../appserv/GlobalContext';
import { new_batch_HH } from '../../middlewares/upload_Handler';

const Batch = () => {
    let { brand } = useParams();
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [category, setCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const itemsPerPage = 10;
    const { userInit } = useContext(GlobalContext);


    const validatePage = async () => {
        try {
            const response = await fetch(`/api/app/brands/all`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userInit.token}`
                }
            });
            if (!response.ok) {
                navigate(`/order-management`);
            }
            const data = await response.json();
            const foundBrand = data.brand.find(b => b.brandID === brand);
            if (foundBrand) {
                return;
            } else {
                navigate(`/order-management`);
            }
        } catch (error) {
            console.error('Fetch error: ', error);
            navigate(`/order-management`);
        }
    };



    useEffect(() => {
        fetchBatches();
        validatePage();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleFilterChange = (e) => {
        setFilterStatus(e.target.value);
        setCurrentPage(1);
    };

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
        setCurrentPage(1);
    };

    const fetchBatches = async (page = 1) => {
        let items = 10;
        const response = await fetch(`/api/app/brand/${brand}/batches?page=${page}&items=${items}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${userInit.token}`
            }
        });
        const data = await response.json();
        setTotalItems(data.totalBatches);
        setData(data.batches);
    };

    const handlePageChange = async (page) => {
        setCurrentPage(page);
        fetchBatches(page);
    };

    const handleDelete = (id) => {
        alert(`handleDelete Succesfully! ${id}`);
    };

    const handleView = (id) => {
        navigate(`${id}`);
    };

    const tblHeaders = [
        { displayName: 'Batch ID', key: 'batchID' },
        { displayName: 'Orders', key: 'totalOrders' },
        { displayName: 'Total Amount', key: 'netTotal' },
        { displayName: 'Notes', key: 'notes' },
        { displayName: 'Ordered By', key: 'orderBy' },
        { displayName: 'Order Date', key: 'createdDate' },
        { displayName: 'Status', key: 'status' },
    ];

    const handleFileUpload = async (file, selectedBrand, notes) => {
        setLoading(true);
        setProgress(30);
        if (file) {
            let newBatchExec = await new_batch_HH(file, brand, selectedBrand, userInit,notes);
            if (newBatchExec !== 0) {
                closeModal();
                setProgress(100);
                setLoading(false);
                window.location.reload();
            }
            else {
                alert('Something Went Wrong! Please check your template');
                closeModal();
                setProgress(100);
                setLoading(false);
            }

        } else {
            alert('No file selected');
        }
    }

    const downloadTemplate = () => {
        try {
            const url = "https://order-swift.s3.ap-southeast-2.amazonaws.com/templates/Order+Swift+-+HH+Order+Template+(v1.0.5).xlsx";
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading template:', error);
            alert('Failed to download template.');
        }
    }

    const downloadSampleTemp = () => {
        alert('Downloaded!');
    }

    const uploadFile = () => {
        setModalContent(<UploadOrder fileHandler={handleFileUpload} templateHandler={downloadTemplate} sampleTemplate={downloadSampleTemp}/>);
        setIsModalOpen(true);
    };

    return (
        <div>
            <Layout>
                <LoadingBar progress={progress} color="#D55440" onLoaderFinished={() => setProgress(0)} height={3} />
                <Modal isOpen={isModalOpen} closeModal={closeModal} contentElements={modalContent} />

                <Table
                    data={data}
                    unique={'batchID'}
                    tHeaders={tblHeaders}
                    onDelete={handleDelete}
                    onView={handleView}
                    searchTerm={searchTerm}
                    handleSearchChange={handleSearchChange}
                    filterStatus={filterStatus}
                    handleFilterChange={handleFilterChange}
                    category={category}
                    handleCategoryChange={handleCategoryChange}
                    title="Batch List"
                    paginationComponent={
                        <Pagination
                            totalItems={totalItems}
                            itemsPerPage={itemsPerPage}
                            onPageChange={handlePageChange}
                        />}
                />
                <button onClick={uploadFile} className="hover:scale-105 p-2 bg-red-500 rounded-full text-white flex items-center justify-center fixed bottom-0 right-0 mb-6 mr-6">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                    </svg>
                </button>
            </Layout>
        </div>
    );
};

export default Batch;
