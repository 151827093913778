import React from "react";
import { Typography } from '@mui/joy';

import Layout from "../../components/Layout";

export default function Settings() {
    return (
        <Layout>
            <div className="w-full h-full bg-white p-4">
                <h1>Settings!</h1>
            </div>
        </Layout>
    );
}