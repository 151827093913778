import * as XLSX from 'xlsx';

class Helpers {

    generateFileName = (initial) => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

        const fileName = `${initial}-${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;

        return fileName;
    };

    getTotalItems = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const range = XLSX.utils.decode_range(worksheet['!ref']);

                resolve(range);
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    addDataToExcel = (file, fileName, dataToInput, download = false) => {
        return new Promise((resolve, reject) => {
            const expectedHeaders = 19;
            const reader = new FileReader();

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const range = XLSX.utils.decode_range(worksheet['!ref']);

                const startRow = 1;
                const requiredColumns = 19;
                const headerCount = range.e.c - range.s.c + 1;

                if (headerCount !== expectedHeaders) {
                    return resolve('Invalid');
                }

                const skipColumns = new Set([3, 7, 8, 9, 14, 15, 16, 17, 18]);

                for (let row = startRow; row <= range.e.r; row++) {
                    let missingData = false;

                    for (let col = 0; col < requiredColumns; col++) {
                        if (skipColumns.has(col)) continue;

                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress] || worksheet[cellAddress].v === '') {
                            missingData = true;
                            break;
                        }

                        if (col === 11 && worksheet[cellAddress].v.length > 2) { // Validate column L (index 11)
                            return resolve('Invalid');
                        }
                    }

                    if (missingData) {
                        return resolve('Invalid');
                    }
                }

                const segment = `${dataToInput.segment}`;
                const orderBy = `${dataToInput.orderBy}`;
                const batchID = `${dataToInput.batchID}`;
                const catalog = `${dataToInput.catalog}`;

                for (let i = startRow; i <= range.e.r; i++) {
                    worksheet[`P${i + 1}`] = { t: 's', v: segment };
                    worksheet[`Q${i + 1}`] = { t: 's', v: orderBy };
                    worksheet[`R${i + 1}`] = { t: 's', v: batchID };
                    worksheet[`S${i + 1}`] = { t: 's', v: catalog };
                }

                const modifiedData = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
                const modifiedFile = new Blob([modifiedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                if (download) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = URL.createObjectURL(modifiedFile);
                    downloadLink.download = fileName;
                    downloadLink.click();
                }

                resolve(modifiedFile);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsArrayBuffer(file);
        });
    };






}

export default Helpers;














