import React, { useState, useEffect } from "react";
import logo from '../../../assets/images/logo-clean.png';

const Modal = ({ isOpen, closeModal, content, contentElements }) => {
    const [isModalOpen] = useState(false);

    const handleModalContentClick = (event) => {
        event.stopPropagation();
    };


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (isModalOpen && !event.target.closest('#modal-content')) {
                closeModal();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isModalOpen, closeModal]);


    const ModalContent = ({ content, element=null }) => {
        return (
            <div id="modal-content" className="bg-white flex p-6 rounded-lg m-5 shadow-md min-w-80 min-h-10 max-w-96 h-auto justify-center flex-col items-center border-t-[0.7rem] border-[#D55440]">
                <div className="flex justify-center mb-2 p-1 w-[3rem] h-[4rem] scale-125 transition">
                    <img className="h-full w-full" src={logo} alt='Order Swift' />
                </div>
                <h2 className="font-bold text-2xl mb-1">{content.header}</h2>
                <h5 className="text-sm mb-3">{content.subtitle}</h5>
                <div className="bg-[#cee6e9] rounded-md h-full flex flex-col justify-center text-center p-6 max-w-80">
                    {content.content ? <p>{content.content}</p> : element}
                </div>
            </div>
        );
    };
    

    return (
        <>
            {isOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-40 modal-overlay z-20" onClick={closeModal}>
                    <div className="modal-content" onClick={handleModalContentClick}>
                        <ModalContent content={content} element={contentElements} />
                        
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
